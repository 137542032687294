import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './FichaReceivedComponent.module.css';
import { MdDeleteOutline, MdEdit, MdKeyboardArrowLeft, MdOutlinePictureAsPdf } from 'react-icons/md';
import { IoRepeatOutline, IoClose } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { Link } from 'react-router-dom';
import apiAxios from '../../../api/apiAxios';
import InterfaceStructure from '../../../components/InterfaceStructure';
import FichaEditReceivedComponent from './FichaEditReceivedComponent';
import useTenantId from '../../tenantId';
import img from '../../../assets/notFound/OIP.jpg';
import { itemsPagesWithExclusions } from '../../itemsPage/itemsPage';
import BasicPageStructure from '../../BasicPageStructure';
import { FaArchive } from "react-icons/fa";
import MiniPopUpComponent from '../../popUp/MiniPopUpComponent';
import useUserRoles from '../../useUserRoles/useUserRoles';

const FichaReceivedComponent = ({ formId, tenantIdProp, acessType }) => {
    const tenantIdFromHook = useTenantId(); // Sempre chama o hook
    const tenantId = tenantIdProp || tenantIdFromHook; // Usa o valor da prop se fornecido, caso contrário, usa o valor do hook

    const [responses, setResponses] = useState([]);
    const [month, setMonth] = useState(''); // Estado para o mês
    const [year, setYear] = useState('');   // Estado para o ano
    const [isReversed, setIsReversed] = useState(false); // Estado para controlar a ordem
    const [selectedResponse, setSelectedResponse] = useState(null); // Estado para a resposta selecionada
    const [showEditPopup, setShowEditPopup] = useState(false); // Estado para mostrar o popup
    const [searchTerm, setSearchTerm] = useState('');
    const [expandedResponses, setExpandedResponses] = useState([]); // Estado para controlar respostas expandidas

    const [showArchivePopup, setShowArchivePopup] = useState(false);
    const [responseToArchive, setResponseToArchive] = useState(null);

    const userRoles = useUserRoles();

    const roleMap = {
        isAdmin: "Administrador",
        isCoordenacao: "Coordenação", // Corrigido para combinar com a possível chave correta
        isDiretor: "Diretor",
        isNutricionista: "Nutricionista",
        isProfessor: "Professor",
        isSecretaria: "Secretaria"
    };
    
    // Função para normalizar chaves (remover acentos e corrigir variações)
    const normalizeKey = (key) => {
        return key.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace("isCoordenaçao", "isCoordenacao");
    };
    
    const activeRoles = Object.entries(userRoles.roles)
        .filter(([key, value]) => value) // Filtra apenas os valores true
        .map(([key]) => roleMap[normalizeKey(key)] || key); // Converte para nome legível



    const handleArchive = (response) => {
        setResponseToArchive(response);
        setShowArchivePopup(true); // Exibe o popup
    };

    const confirmArchive = async () => {
        try {
            const newArchivedStatus = !responseToArchive.archived; // Inverter o estado de arquivamento
            await apiAxios.patch(`/api/formresponse/${responseToArchive._id}/archive`, { archived: newArchivedStatus });
            setResponses((prevResponses) =>
                prevResponses.map((response) =>
                    response._id === responseToArchive._id
                        ? { ...response, archived: newArchivedStatus }
                        : response
                )
            );
            setShowArchivePopup(false); // Fecha o popup após arquivar
        } catch (error) {
            console.error('Erro ao arquivar/desarquivar resposta:', error);
        }
    };


    // Função para alternar o estado expandido de uma resposta
    const toggleExpand = (responseId) => {
        setExpandedResponses((prev) =>
            prev.includes(responseId)
                ? prev.filter((id) => id !== responseId)
                : [...prev, responseId]
        );
    };

    useEffect(() => {
        const fetchResponses = async () => {
            try {
                const response = await apiAxios.get('/api/formresponse', {
                    params: { tenantId }
                });
                setResponses(response.data);

                // Definir o mês e o ano atuais como valores padrão
                const currentDate = new Date();
                setMonth((currentDate.getMonth() + 1).toString()); // getMonth() retorna 0-11
                setYear(currentDate.getFullYear().toString());
            } catch (error) {
                console.error('Erro ao buscar respostas:', error);
            }
        };

        fetchResponses();
    }, [tenantId]);

    // Lógica de filtros e ordenação (id, mês, ano, busca)
    const filteredByForm = formId ? responses.filter(response => response.form._id === formId) : responses;

    const filteredResponses = filteredByForm.filter(response => {
        const responseDate = new Date(response.createdAt);
        const responseMonth = responseDate.getMonth() + 1;
        const responseYear = responseDate.getFullYear();

        const isMonthMatch = month ? responseMonth === parseInt(month) : true;
        const isYearMatch = year ? responseYear === parseInt(year) : true;

        return isMonthMatch && isYearMatch;
    });

    const sortedResponses = filteredResponses
        .slice()
        .sort((a, b) => {
            // Itens arquivados aparecem após os não arquivados
            if (a.archived === b.archived) {
                return 0; // Mantém a ordem original se ambos têm o mesmo status
            }
            return a.archived ? 1 : -1; // Arquivados vão para o final
        });

    // Reverter a ordem se `isReversed` estiver ativo
    if (isReversed) {
        sortedResponses.reverse();
    }

    const filteredBySearchTerm = sortedResponses.filter(response =>
        response.form.fields.some(field =>
            field.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
            response.answers.some(answer => answer.answer.toLowerCase().includes(searchTerm.toLowerCase()))
        )
    );


    const handleDownloadPDF = async () => {
        try {
            // Certifique-se de passar o 'year' no corpo da requisição
            const response = await apiAxios.post(`/api/formresponse/form/${formId}/report`, {
                year: year // Passar o ano corretamente no corpo da requisição
            }, {
                responseType: 'blob', // Configurar o tipo de resposta para 'blob'
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Form_Report_${formId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao baixar o PDF:', error);
        }
    };

    const handleDownloadIndividualPDF = async (responseId) => {
        try {
            const response = await apiAxios.get(`/api/formresponse/response/${responseId}/report`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Response_Report_${responseId}.pdf`);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error('Erro ao baixar o PDF individual:', error);
        }
    };

    const handleDelete = async (id) => {
        if (window.confirm('Tem certeza de que deseja excluir esta resposta?')) {
            try {
                await apiAxios.delete(`/api/formresponse/${id}`);
                setResponses(responses.filter(response => response._id !== id));
            } catch (error) {
                console.error('Erro ao excluir resposta:', error);
            }
        }
    };

    const handleEditClick = (response) => {
        setSelectedResponse(response);
        setShowEditPopup(true);
    };

    const handleHidePopup = () => {
        setShowEditPopup(false);
        setSelectedResponse(null);
    };

    const months = Array.from({ length: 12 }, (_, i) => i + 1);
    const years = Array.from(new Set(responses.map(response => new Date(response.createdAt).getFullYear())));

    const itemsPagesList = itemsPagesWithExclusions('');

    const dashboardContent = (
        <>
            <div className={styles.initialArea}>
                <div className={styles.content}>
                    <div>
                        <h2>{filteredByForm.filter(response => !response.archived).length}</h2>
                        <p>Total de Fichas</p>
                    </div>
                    <div>
                        <h2>
                            {year
                                ? filteredByForm.filter(response =>
                                    !response.archived &&
                                    new Date(response.createdAt).getFullYear() === parseInt(year)
                                ).length
                                : '-'}
                        </h2>
                        <p>Total de Fichas do Ano</p>
                    </div>
                    <div>
                        <h2>
                            {month && year
                                ? filteredByForm.filter(response => {
                                    const responseDate = new Date(response.createdAt);
                                    return (
                                        !response.archived &&
                                        responseDate.getMonth() + 1 === parseInt(month) &&
                                        responseDate.getFullYear() === parseInt(year)
                                    );
                                }).length
                                : '-'}
                        </h2>
                        <p>Total de Fichas do Mês</p>
                    </div>
                </div>
            </div>


            <div className={styles.filterArea} >
                <div className={styles.group}>
                    <label htmlFor="year">Ano:</label>
                    <select id="year" value={year} onChange={(e) => setYear(e.target.value)}>
                        {years.map(y => (
                            <option key={y} value={y}>{y}</option>
                        ))}
                    </select>
                </div>
                <div className={styles.group}>
                    <label htmlFor="month">Mês:</label>
                    <select id="month" value={month} onChange={(e) => setMonth(e.target.value)}>
                        <option value="">Todos</option>
                        {months.map(m => (
                            <option key={m} value={m}>{m}</option>
                        ))}
                    </select>
                </div>
                <div className={styles.group}>
                    <div className={styles.revert}>
                        <button onClick={() => setIsReversed(!isReversed)}>
                            <IoRepeatOutline />
                        </button>
                    </div>

                </div>
                <div style={{ marginTop: "20px" }} className={styles.group}>
                    <div className={styles.inputSearch}>
                        <CiSearch />
                        <input
                            type="text"
                            placeholder="Pesquisar..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </div>
                </div>
                <div className={styles.group}>
                    <div className={styles.revert}>
                        <button onClick={handleDownloadPDF}>
                            <MdOutlinePictureAsPdf />
                        </button>
                    </div>
                </div>
            </div>

            <div className={styles.bottomAreaInfo}>
                {sortedResponses.length === 0 ? (
                    <div className={styles.noResponses}>
                        <img src={img} alt="No Responses" />
                        <p>Não há respostas disponíveis</p>
                    </div>
                ) : (
                    <div className={styles.responsesList}>
                        {filteredBySearchTerm.map(response => (
                            <div key={response._id} className={`${response.archived ? styles.responseArchived : styles.responseItem} `}>
                                <div className={styles.responseActions}>
                                    <div className={styles.left}>
                                    </div>
                                    <div className={styles.right}>
                                        <button onClick={() => handleDownloadIndividualPDF(response._id)} className={styles.pdfDownload}>
                                            <MdOutlinePictureAsPdf />
                                        </button>

                                        {acessType !== "low" && (
                                            <>
                                                <button
                                                    onClick={() => handleArchive(response)}
                                                    className={styles.archiveButton}
                                                >
                                                    <FaArchive />
                                                </button>

                                                <button onClick={() => handleEditClick(response)} className={styles.updateButton}>
                                                    <MdEdit />
                                                </button>

                                                <button onClick={() => handleDelete(response._id)}>
                                                    <MdDeleteOutline />
                                                </button>
                                            </>
                                        )}
                                    </div>

                                </div>
                                <div className={styles.responseDetails}>
                                    {response.form.fields.map((question, index) => {
                                        const answer = response.answers.find(a => a.fieldId === question._id);

                                        if (!expandedResponses.includes(response._id) && index >= 4) return null;

                                        return (
                                            <div key={question._id} className={styles.answerItem}>
                                                <p className={styles.question}>{question.name}</p>
                                                <p className={styles.answer}>{answer ? answer.answer : 'Sem resposta'}</p>
                                            </div>
                                        );
                                    })}

                                    {response.form.fields.length > 3 && (
                                        <button
                                            className={styles.toggleButton}
                                            onClick={() => toggleExpand(response._id)}
                                        >
                                            {expandedResponses.includes(response._id) ? 'Ver menos' : 'Ver mais'}
                                        </button>
                                    )}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>

            {showEditPopup && selectedResponse && (
                <div className={styles.popupOverlay} onClick={handleHidePopup}>
                    <div className={styles.popupContent} onClick={(e) => e.stopPropagation()}>
                        <FichaEditReceivedComponent response={selectedResponse} />
                        <button onClick={handleHidePopup} className={styles.closeButton}><IoClose /></button>
                    </div>
                </div>
            )}
        </>
    );

    const buttons = [
    ];

    return (
        <>
            <InterfaceStructure itemsPages={itemsPagesList} dashboardContent={
                <BasicPageStructure
                    content={dashboardContent}
                    buttons={buttons}
                    returnPage={true}
                    returnLabel='Fichas'
                    returnPath='/fichas'
                    namePage={
                        formId
                            ? responses.map(response => response.form) // Mapeia para pegar o título
                                .filter(response => response?._id === formId) // Filtra os objetos que têm _id igual ao formId 
                                .map(response => response.title)
                                .slice(0, 1) : 'Fichas recebidas'
                    }
                    accessType={activeRoles}
                />}
            />
            {showArchivePopup && (
                <MiniPopUpComponent
                    title="Confirmar Arquivamento"
                    message={`Deseja realmente ${responseToArchive?.archived ? 'desarquivar' : 'arquivar'} esta ficha?`}
                    onConfirm={confirmArchive}
                    onCancel={() => setShowArchivePopup(false)} // Fecha o popup sem arquivar
                />
            )}

        </>
    );
};

FichaReceivedComponent.propTypes = {
    formId: PropTypes.string,
    tenantIdProp: PropTypes.string,
};

export default FichaReceivedComponent;
