import React from 'react';
import useTenantId from '../../../components/tenantId';
import HorariosPageComponent from '../../../components/pagesComponents/HorariosComponents/HorariosPageComponent';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const HorariosCoordenacaoPage = () => {
    const tenantId = useTenantId();
    const itemsPagesList = itemsPagesWithExclusions('Horários', ['agenda','Informes','Atividades','Chat','Cardápio']);

    return (
        <HorariosPageComponent tenantId={tenantId} pagesList={itemsPagesList} accessLevel="highAccess" />
    );
};

export default HorariosCoordenacaoPage;
