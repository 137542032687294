import React, { useState } from 'react';
import apiAxios from '../../../api/apiAxios';
import styles from './NotificacoesCreateComponent.module.css';
import { series as seriesPadrao, turno as turnoPadrao } from '../../../DataSeries';

const NotificacoesCreateComponent = ({ tenantId, seriesRecebidas = [], turnosRecebidas = [] }) => {
    // Definição de séries e turnos com base nos valores recebidos
    const series = seriesRecebidas.length > 0 ? seriesRecebidas : seriesPadrao;
    const turnos = turnosRecebidas.length > 0 ? turnosRecebidas : turnoPadrao;

    // Estado inicial do formulário
    const [formData, setFormData] = useState({
        topico: '',
        titulo: '',
        descricao: '',
        tipo: 'Informativo',
        status: 'Inativo',
        prioridade: 0,
        acessoTurmas: {
            serie: series.length > 0 ? series[0] : '',
            turno: turnos.length > 0 ? turnos[0] : ''
        },
        anexos: [],
        criadoPor: 'admin',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value
        }));
    };

    const handleAcessoTurmasChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            acessoTurmas: {
                ...prev.acessoTurmas,
                [name]: value
            }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await apiAxios.post('/api/avisos', { ...formData, tenantId });
            console.log('✅ Notificação criada com sucesso:', response.data);
            alert('Notificação criada com sucesso!');
            setFormData({
                topico: '',
                titulo: '',
                descricao: '',
                tipo: 'Informativo',
                status: 'Ativo',
                prioridade: 0,
                acessoTurmas: {
                    serie: series.length > 0 ? series[0] : '',
                    turno: turnos.length > 0 ? turnos[0] : ''
                },
                anexos: [],
                criadoPor: 'admin',
            });
        } catch (error) {
            console.error('❌ Erro ao criar notificação:', error);
            alert('Erro ao criar notificação!');
        }
    };

    return (
        <div className={styles.container}>
            <form onSubmit={handleSubmit} className={styles.form}>
                <label className={styles.label}>Tópico:</label>
                <input type="text" name="topico" value={formData.topico} onChange={handleChange} required className={styles.input} />

                <label className={styles.label}>Título:</label>
                <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} required className={styles.input} />

                <label className={styles.label}>Descrição:</label>
                <textarea name="descricao" value={formData.descricao} onChange={handleChange} required className={styles.textarea} />

                <label className={styles.label}>Tipo:</label>
                <select name="tipo" value={formData.tipo} onChange={handleChange} className={styles.select}>
                    <option value="Informativo">Informativo</option>
                    <option value="Importante">Importante</option>
                    <option value="Urgente">Urgente</option>
                </select>

                <label className={styles.label}>Série:</label>
                <select name="serie" value={formData.acessoTurmas.serie} onChange={handleAcessoTurmasChange} className={styles.select}>
                    {series.map((s, index) => (
                        <option key={index} value={s}>{s}</option>
                    ))}
                </select>

                <label className={styles.label}>Turno:</label>
                <select name="turno" value={formData.acessoTurmas.turno} onChange={handleAcessoTurmasChange} className={styles.select}>
                    {turnos.map((t, index) => (
                        <option key={index} value={t}>{t}</option>
                    ))}
                </select>

                <label className={styles.label}>Prioridade:</label>
                <input type="number" name="prioridade" value={formData.prioridade} onChange={handleChange} className={styles.input} />

                <button type="submit" className={styles.button}>Criar Notificação</button>
            </form>
        </div>
    );
};

export default NotificacoesCreateComponent;
