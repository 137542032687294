import React from 'react';
import { useSelector } from "react-redux";

const useUserId = () => {
  const userInfo = useSelector(state => state.user.userInfo);
  const tokenPayload = JSON.parse(atob(userInfo.token.split('.')[1]));
  return tokenPayload._id; // Retorna apenas o _id
};

export default useUserId;
