import React, { useEffect, useState } from "react";
import styles from "./BoletimInfantilComponent.module.css";
import apiAxios from "../../../../api/apiAxios";

const BoletimInfantilComponent = ({ boletimAluno, tenantId, boletimId, alunoId }) => {
    const [relatorios, setRelatorios] = useState(boletimAluno.relatorios || []);
    const [alunoBoletimId, setAlunoBoletimId] = useState(boletimAluno._id || null);
    const [isUpdating, setIsUpdating] = useState(false);

    const [periodo,setPediodo] = useState([]);

    console.log("📌 Boletim Infantil recebido:", boletimAluno);

    const fetchBoletim = async () => {
        if (!alunoBoletimId) {
            console.warn("⚠️ ID do boletim do aluno não definido. Cancelando requisição.");
            return;
        }

        try {
            console.log("🔍 Buscando boletim infantil com ID:", alunoBoletimId);
            const response = await apiAxios.get(`/api/boletims/boletins/aluno/${alunoBoletimId}`);
            if (response.data && response.data.data) {
                setRelatorios(response.data.data.relatorios || []);
                console.log("✅ Dados do boletim infantil atualizados!");
            }
        } catch (error) {
            console.error("❌ Erro ao buscar boletim infantil atualizado:", error);
        }
    };

    useEffect(() => {
        if (alunoBoletimId) fetchBoletim();
    }, [boletimId, alunoId]);

    const handleTipoChange = async (areaGeralIndex, especificaIndex, competenciaIndex, periodoIndex, newTipo) => {
        if (isUpdating) return;

        setIsUpdating(true);

        const updatedRelatorios = [...relatorios];
        updatedRelatorios[areaGeralIndex].areasEspecificas[especificaIndex].competencias[competenciaIndex].periodos[periodoIndex].tipo = newTipo;

        setRelatorios(updatedRelatorios);

        try {
            if (alunoBoletimId) {
                console.log("🔄 Enviando atualização:", JSON.stringify(updatedRelatorios, null, 2));

                await apiAxios.put(`/api/boletims/boletins/aluno/${alunoBoletimId}`, {
                    tenantId,
                    boletimId,
                    alunoId,
                    relatorios: updatedRelatorios,
                });

                console.log("✅ Relatório atualizado com sucesso!");

                setTimeout(async () => {
                    await fetchBoletim();
                    setIsUpdating(false);
                }, 1000);
            } else {
                console.error("❌ Erro: ID do boletim do aluno não encontrado.");
                setIsUpdating(false);
            }
        } catch (error) {
            console.error("❌ Erro ao atualizar o relatório:", error);
            setIsUpdating(false);
        }
    };

    const calculateRowSpanForGeneralArea = (generalArea) => {
        return generalArea.areasEspecificas.reduce(
            (acc, especifica) => acc + especifica.competencias.length,
            0
        );
    };

    const uniquePeriods = relatorios.length > 0
    ? [...new Set(relatorios.flatMap(area => 
        area.areasEspecificas.flatMap(especifica => 
            especifica.competencias.flatMap(comp => 
                comp.periodos.map(p => p.periodo)
            )
        )
    ))]
    : [];


    return (
        <div className={styles.container}>
            <h3 className={styles.title}>Relatório de Desenvolvimento</h3>
            <table className={styles.boletimTabela}>
                <thead>
                    <tr>
                        <th rowSpan={2}>Área Geral</th>
                        <th rowSpan={2}>Competência</th>
                        <th colSpan={uniquePeriods.length} className={styles.periodHeader}>Bimestres</th>
                    </tr>
                    <tr>
                        {uniquePeriods.map((period, idx) => (
                            <th key={`period-header-${idx}`}>{period}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {relatorios.map((generalArea, generalIdx) => (
                        generalArea.areasEspecificas.map((especifica, especificaIdx) =>
                            especifica.competencias.map((competencia, compIdx) => (
                                <tr key={`${generalIdx}-${especificaIdx}-${compIdx}`}>
                                    {/* Área Geral apenas na primeira competência */}
                                    {especificaIdx === 0 && compIdx === 0 && (
                                        <td rowSpan={calculateRowSpanForGeneralArea(generalArea)} className={styles.areaGeralCell}>
                                            {generalArea.areaGeral.nome || "Área Geral não definida"}
                                        </td>
                                    )}
                                    {/* Competência apenas na primeira linha do grupo de períodos */}
                                    <td>{competencia.competencia || "Competência não definida"}</td>
                                    {uniquePeriods.map((periodoNome, periodoIdx) => {
                                        const periodo = competencia.periodos.find(p => p.periodo === periodoNome) || { tipo: "EP" };
                                        return (
                                            <td key={`${generalIdx}-${especificaIdx}-${compIdx}-${periodoIdx}`}>
                                                <select
                                                    value={periodo.tipo}
                                                    onChange={(e) => handleTipoChange(generalIdx, especificaIdx, compIdx, periodoIdx, e.target.value)}
                                                >
                                                    <option value="S">Sim</option>
                                                    <option value="N">Não</option>
                                                    <option value="EP">Em Progresso</option>
                                                </select>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))
                        )
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default BoletimInfantilComponent;
