import React, { useState, useEffect } from 'react';
import apiAxios from '../../../api/apiAxios';
import styles from './NotificacoesEditComponent.module.css';
import { series as seriesPadrao, turno as turnoPadrao } from '../../../DataSeries';

const NotificacoesEditComponent = ({ tenantId, notificacaoId, onClose, serieSelecionada, turnoSelecionado }) => {
    const [formData, setFormData] = useState({
        topico: '',
        titulo: '',
        descricao: '',
        tipo: 'Informativo',
        status: 'Ativo',
        prioridade: 0,
        acessoTurmas: { 
            serie: serieSelecionada || seriesPadrao[0] || '', 
            turno: turnoSelecionado || turnoPadrao[0] || ''
        },
        anexos: [],
        atualizadoPor: 'admin',
    });

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchNotificacao = async () => {
            try {
                const response = await apiAxios.get(`/api/avisos/${notificacaoId}`);

                let acessoTurmas = { serie: serieSelecionada, turno: turnoSelecionado };

                if (Array.isArray(response.data.acessoTurmas) && response.data.acessoTurmas.length > 0) {
                    acessoTurmas = response.data.acessoTurmas[0];
                }

                setFormData({
                    ...response.data,
                    acessoTurmas
                });
            } catch (error) {
                console.error('Erro ao carregar notificação:', error);
            } finally {
                setLoading(false);
            }
        };

        if (notificacaoId) {
            fetchNotificacao();
        }
    }, [notificacaoId, serieSelecionada, turnoSelecionado]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleAcessoTurmasChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            acessoTurmas: { ...prevState.acessoTurmas, [name]: value }
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await apiAxios.patch(`/api/avisos/${notificacaoId}`, { ...formData, tenantId });
            alert('Notificação atualizada com sucesso!');
            onClose();
        } catch (error) {
            console.error('Erro ao atualizar notificação:', error);
            alert('Erro ao atualizar notificação!');
        }
    };

    return (
        <div className={styles.container}>
            {loading ? (
                <p>Carregando dados...</p>
            ) : (
                <form onSubmit={handleSubmit} className={styles.form}>
                    <label className={styles.label}>Tópico:</label>
                    <input type="text" name="topico" value={formData.topico} onChange={handleChange} required className={styles.input} />

                    <label className={styles.label}>Título:</label>
                    <input type="text" name="titulo" value={formData.titulo} onChange={handleChange} required className={styles.input} />

                    <label className={styles.label}>Descrição:</label>
                    <textarea name="descricao" value={formData.descricao} onChange={handleChange} required className={styles.textarea} />

                    <label className={styles.label}>Tipo:</label>
                    <select name="tipo" value={formData.tipo} onChange={handleChange} className={styles.select}>
                        <option value="Informativo">Informativo</option>
                        <option value="Importante">Importante</option>
                        <option value="Urgente">Urgente</option>
                    </select>

                    <label className={styles.label}>Série:</label>
                    <select name="serie" value={formData.acessoTurmas.serie} onChange={handleAcessoTurmasChange} className={styles.select}>
                        {(serieSelecionada ? [serieSelecionada] : seriesPadrao).map((s, index) => (
                            <option key={index} value={s}>{s}</option>
                        ))}
                    </select>

                    <label className={styles.label}>Turno:</label>
                    <select name="turno" value={formData.acessoTurmas.turno} onChange={handleAcessoTurmasChange} className={styles.select}>
                        {(turnoSelecionado ? [turnoSelecionado] : turnoPadrao).map((t, index) => (
                            <option key={index} value={t}>{t}</option>
                        ))}
                    </select>

                    <label className={styles.label}>Prioridade:</label>
                    <input type="number" name="prioridade" value={formData.prioridade} onChange={handleChange} className={styles.input} />

                    <button type="submit" className={styles.button}>Atualizar</button>
                </form>
            )}
        </div>
    );
};

export default NotificacoesEditComponent;
