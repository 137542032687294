import React from 'react';
import { turno } from '../../../DataSeries';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import PlanosComponent from '../../../components/pagesComponents/planosComponents/PlanosComponent';
const turnos = turno;

const PlanosCoordenacaoPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Planos', ['agenda','Informes','Atividades','Chat','Cardápio']);
    const allowedSeries =  [];

    return (
        <>
            <PlanosComponent itemsPages={itemsPagesList} accessLevel="high" seriesAccess={allowedSeries} />
        </>
    );
};

export default PlanosCoordenacaoPage;
 