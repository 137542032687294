import React, { useState, useEffect } from 'react';
import apiAxios from '../../../api/apiAxios';
import styles from './NotificacoesComponent.module.css';
import {
    IoIosAddCircleOutline,
    IoMdNotificationsOutline,
    IoMdCreate,
    IoMdWarning,
    IoMdCheckmarkCircle,
    IoMdAlert,
    IoMdCloseCircle,
    IoMdHelpCircle,
    IoMdFlash,
    IoMdEye,
} from 'react-icons/io';
import PopUpComponent from '../../popUp/PopUpComponent';
import NotificacoesCreateComponent from './NotificacoesCreateComponent';
import NotificacoesEditComponent from './NotificacoesEditComponent';
import useTenantId from '../../tenantId';
import { series as seriesPadrao, turno as turnoPadrao } from '../../../DataSeries';
import BasicPageStructure from '../../BasicPageStructure';
import InterfaceStructure from '../../InterfaceStructure';
import { IoMdTrash } from 'react-icons/io';
import { IoMdLock, IoMdUnlock } from 'react-icons/io';
import useUserRoles from '../../useUserRoles/useUserRoles';

const NotificacoesComponent = ({ itemsPages, seriesRecebidas, turnosRecebidas }) => {
    const [notificacoes, setNotificacoes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [popupOpen, setPopupOpen] = useState(false);
    const [popupCreateOpen, setPopupCreateOpen] = useState(false);
    const [popupEditOpen, setPopupEditOpen] = useState(false);
    const [selectedNotificacao, setSelectedNotificacao] = useState(null);

    // Usa as séries e turnos recebidos, se existirem. Caso contrário, usa os valores padrão
    const series = seriesRecebidas?.length ? seriesRecebidas : seriesPadrao;
    const turnos = turnosRecebidas?.length ? turnosRecebidas : turnoPadrao;

    const [serieSelecionada, setSerieSelecionada] = useState("");
    const [turnoSelecionado, setTurnoSelecionado] = useState("");

    const { roles } = useUserRoles();
    const podeGerenciarNotificacoes = roles.isAdmin || roles.isCoordenaçao || roles.isDiretor || roles.isSecretaria;



    const tenantId = useTenantId();

    useEffect(() => {
        if (seriesRecebidas?.length > 0 && turnosRecebidas?.length > 0) {
            setSerieSelecionada(seriesRecebidas[0]);
            setTurnoSelecionado(turnosRecebidas[0]);
        } else {
            setSerieSelecionada(seriesPadrao[0]);
            setTurnoSelecionado(turnoPadrao[0]);
        }
    }, [seriesRecebidas, turnosRecebidas]);

    useEffect(() => {
        const fetchNotificacoes = async () => {
            setLoading(true);
            try {
                const response = await apiAxios.get(`/api/avisos`);

                console.log("🔍 Notificações recebidas da API:", response.data); // Verifique os dados recebidos

                const avisosFiltrados = response.data.filter(aviso =>
                    aviso.tenantId === tenantId &&
                    aviso.acessoTurmas.some(turma => {
                        const serieNotificacao = String(turma.serie).trim().toLowerCase();
                        const turnoNotificacao = String(turma.turno).trim().toLowerCase();
                        const serieSelecionadaNormalizada = String(serieSelecionada).trim().toLowerCase();
                        const turnoSelecionadoNormalizado = String(turnoSelecionado).trim().toLowerCase();

                        return (
                            (Array.isArray(turma.serie) ? turma.serie.map(s => s.trim().toLowerCase()).includes(serieSelecionadaNormalizada) : serieNotificacao === serieSelecionadaNormalizada) &&
                            (Array.isArray(turma.turno) ? turma.turno.map(t => t.trim().toLowerCase()).includes(turnoSelecionadoNormalizado) : turnoNotificacao === turnoSelecionadoNormalizado)
                        );
                    })
                );


                console.log("✅ Notificações após filtragem:", avisosFiltrados);
                setNotificacoes(avisosFiltrados);
            } catch (error) {
                console.error("❌ Erro ao buscar notificações:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchNotificacoes();
    }, [tenantId, serieSelecionada, turnoSelecionado]);


    const openPopup = (notificacao) => {
        setSelectedNotificacao(notificacao);
        setPopupOpen(true);
    };

    const openEditPopup = (notificacao) => {
        setSelectedNotificacao(notificacao);
        setPopupEditOpen(true);
    };

    const renderPriorityIcon = (prioridade) => {
        switch (prioridade) {
            case 0:
                return <IoMdCheckmarkCircle className={styles.prioridadeBaixa} title="Baixa Prioridade" />;
            case 1:
                return <IoMdWarning className={styles.prioridadeMediaBaixa} title="Prioridade Média Baixa" />;
            case 2:
                return <IoMdHelpCircle className={styles.prioridadeMedia} title="Prioridade Média" />;
            case 3:
                return <IoMdAlert className={styles.prioridadeAlta} title="Alta Prioridade" />;
            case 4:
                return <IoMdCloseCircle className={styles.prioridadeMuitoAlta} title="Muito Alta Prioridade" />;
            case 5:
                return <IoMdFlash className={styles.prioridadeUrgente} title="Urgente!" />;
            default:
                return <IoMdCheckmarkCircle className={styles.prioridadeBaixa} title="Prioridade Desconhecida" />;
        }
    };

    const handleDelete = async (id) => {
        const confirmDelete = window.confirm("Tem certeza que deseja excluir esta notificação?");
        if (!confirmDelete) return;

        try {
            await apiAxios.delete(`/api/avisos/${id}`);
            alert('Notificação excluída com sucesso!');
            setNotificacoes(prevNotificacoes => prevNotificacoes.filter(notificacao => notificacao._id !== id));
        } catch (error) {
            console.error("Erro ao excluir notificação:", error);
            alert('Erro ao excluir notificação!');
        }
    };

    const formatarData = (dataString) => {
        if (!dataString) return "Data inválida"; // Tratamento para valores indefinidos ou nulos

        const data = new Date(dataString);

        if (isNaN(data.getTime())) {
            return "Data inválida"; // Tratamento para datas inválidas
        }

        return data.toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: 'long', // Nome do mês completo
            year: 'numeric',
            weekday: 'long' // Nome do dia da semana completo
        });
    };

    const handleToggleStatus = async (id, statusAtual) => {
        const novoStatus = statusAtual === "Ativo" ? "Inativo" : "Ativo"; // Alterna entre "Ativo" e "Inativo"

        try {
            await apiAxios.patch(`/api/avisos/${id}`, { status: novoStatus });
            alert(`Notificação agora está ${novoStatus.toLowerCase()}!`);

            // Atualiza o estado para refletir a mudança sem precisar recarregar
            setNotificacoes(prevNotificacoes =>
                prevNotificacoes.map(notificacao =>
                    notificacao._id === id ? { ...notificacao, status: novoStatus } : notificacao
                )
            );
        } catch (error) {
            console.error("Erro ao alterar status da notificação:", error);
            alert("Erro ao alterar status da notificação!");
        }
    };


    const dashboardContent = (
        <>
            <div className={styles.notificacoesContainer}>
                {/* Seletor de Série e Turno */}
                <div className={styles.filtros}>
                    <div>
                        <label className={styles.label}>Série:</label>
                        <select className={styles.select} value={serieSelecionada} onChange={(e) => setSerieSelecionada(e.target.value)}>
                            {series.map((serie, index) => (
                                <option key={index} value={serie}>{serie}</option>
                            ))}
                        </select>
                    </div>
                    <div>
                        <label className={styles.label}>Turno:</label>
                        <select className={styles.select} value={turnoSelecionado} onChange={(e) => setTurnoSelecionado(e.target.value)}>
                            {turnos.map((turno, index) => (
                                <option key={index} value={turno}>{turno}</option>
                            ))}
                        </select>
                    </div>

                </div>

                {loading ? (
                    <p>Carregando notificações...</p>
                ) : notificacoes.length > 0 ? (
                    <ul className={styles.listaNotificacoes}>
                        {notificacoes.map((notificacao) => (
                            <li key={notificacao._id} className={styles.notificacaoItem}>
                                <div className={styles.cabecalho}>
                                    <div>
                                        <span className={`${styles.tipo} ${styles[notificacao.tipo.toLowerCase()]}`}>
                                            {notificacao.tipo + " "}
                                        </span>
                                        <span className={styles.data}>
                                            {formatarData(notificacao.criadoEm)}
                                        </span>
                                    </div>
                                    <strong>{notificacao.titulo}</strong>
                                </div>
                                <p>{notificacao.descricao}</p>
                                <div className={styles.rodape}>
                                    <div style={{ display: "flex", alignItems: "end", gap: "10px" }}>
                                        <span className={styles.prioridadeIcon}>
                                            {renderPriorityIcon(notificacao?.prioridade)}
                                        </span>
                                        <div className={styles.prioridadeIcon} style={{ borderRadius: "10px" }}>
                                            {Object.entries(
                                                notificacao?.interacoes?.reduce((acc, interacao) => {
                                                    acc[interacao.tipo] = (acc[interacao.tipo] || 0) + 1;
                                                    return acc;
                                                }, {})
                                            ).map(([tipo, count]) => (
                                                <span key={tipo} style={{ display: "flex", alignItems: "center", fontWeight: "800", color: "gray", fontSize: "15px", gap: "5px" }}>
                                                    {tipo === "Curtir" && "👍"} {/* Emoji para "Curtir" */}
                                                    {tipo === "Amei" && "❤️"} {/* Emoji para "Amei" */}
                                                    {tipo === "Surpreso" && "😲"} {/* Emoji para "Surpreso" */}
                                                    {tipo === "Triste" && "😢"} {/* Emoji para "Triste" */}
                                                    {tipo === "Bravo" && "😡"} {/* Emoji para "Bravo" */}
                                                    {count}
                                                </span>
                                            ))}
                                        </div>
                                        <span style={{ display: "flex", alignItems: "center", justifyContent: "center", padding: "0px", margin: "0px", gap: "2px", paddingBottom: "4px", fontWeight: "800", color: "gray", fontSize: "15px" }} className={styles.visualizacoes}>
                                            <IoMdEye style={{ fontSize: "23px" }} className={styles.eyeIcon} title="Visualizações" /> {notificacao?.visualizadoPor?.length || 0}
                                        </span>
                                        {console.log(notificacao)}
                                    </div>
                                    <div className={styles.acoes}>
                                        {podeGerenciarNotificacoes && (
                                            <button
                                                className={`${styles.toggleButton} ${notificacao.status === "Ativo" ? styles.bloquear : styles.liberar}`}
                                                onClick={() => handleToggleStatus(notificacao._id, notificacao.status)}
                                            >
                                                {notificacao.status === "Ativo" ? (
                                                    <>
                                                        <IoMdLock /> Bloquear Acesso
                                                    </>
                                                ) : (
                                                    <>
                                                        <IoMdUnlock /> Liberar Acesso
                                                    </>
                                                )}
                                            </button>
                                        )}
                                        <button className={styles.deleteButton} onClick={() => handleDelete(notificacao._id)}>
                                            <IoMdTrash /> Deletar
                                        </button>
                                        <button className={styles.editButton} onClick={() => openEditPopup(notificacao)}>
                                            <IoMdCreate /> Editar
                                        </button>
                                    </div>
                                </div>

                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>Nenhuma notificação disponível.</p>
                )}

                {/* Pop-up para criação de notificação */}
                <PopUpComponent
                    title="Criar Notificação"
                    isOpen={popupCreateOpen}
                    onClose={() => setPopupCreateOpen(false)}
                >
                    <NotificacoesCreateComponent tenantId={tenantId} seriesRecebidas={series} turnosRecebidas={turnos} />
                </PopUpComponent>

                {/* Pop-up para edição de notificação */}
                <PopUpComponent
                    title="Editar Notificação"
                    isOpen={popupEditOpen}
                    onClose={() => setPopupEditOpen(false)}
                >
                    {selectedNotificacao && (
                        <NotificacoesEditComponent
                            tenantId={tenantId}
                            notificacaoId={selectedNotificacao._id}
                            serieSelecionada={serieSelecionada}
                            turnoSelecionado={turnoSelecionado}
                            onClose={() => setPopupEditOpen(false)}
                        />
                    )}
                </PopUpComponent>
            </div>
        </>
    );

    const buttons = [
        {
            label: 'Adicionar Notificação',
            icon: IoIosAddCircleOutline,
            onClick: () => setPopupCreateOpen(true),
        },
    ];

    return (
        <InterfaceStructure itemsPages={itemsPages} dashboardContent={
            <BasicPageStructure
                content={dashboardContent}
                buttons={buttons}
                returnPage={false}
                namePage="Notificações"
                accessType={"Administrador"}
            />
        } />
    );
};

export default NotificacoesComponent;
