import React from 'react';
import useTenantId from '../../../components/tenantId';
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';
import FichasComponent from '../../../components/fichasComponent/FichasComponent';

const FichasCoordenacaoPage = () => {
    const tenantId = useTenantId();  // Obtendo tenantId
    const itemsPagesList = itemsPagesWithExclusions('Fichas',['agenda','Informes','Atividades','Chat','Cardápio']);

    return (
        <FichasComponent PagesList={itemsPagesList} tenantId={tenantId} acessType={"low"}/>
    );
};

export default FichasCoordenacaoPage;