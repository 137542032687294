import React, { useEffect, useState } from "react";
import apiAxios from "../../../api/apiAxios";
import useTenantId from "../../tenantId";
import styles from "./BoletimCreateComponent.module.css";
import useUserId from "../../useUserId";
import { getUserById } from "../../../actions/UserActions";

const BoletimCreateComponent = () => {
    const tenantId = useTenantId();

    // Estado para os campos do formulário
    const [anoLetivo, setAnoLetivo] = useState(new Date().getFullYear().toString());
    const [descricao, setDescricao] = useState("");
    const [nivelEnsino, setNivelEnsino] = useState("");
    const [status, setStatus] = useState("Ativo");
    const [periodType, setPeriodType] = useState("Bimestral"); // Alterado para periodType
    const [responsaveis, setResponsaveis] = useState([{ nome: "", cargo: "" }]);
    const [observacoesGerais, setObservacoesGerais] = useState("");
    const [dataEncerramento, setDataEncerramento] = useState("");
    const [tipoAvaliacaoMax, setTipoAvaliacaoMax] = useState("AV3");
    const [tiposAvaliacao, setTiposAvaliacao] = useState(["AV1", "AV2", "AV3"]);
    const [mediaGeral, setMediaGeral] = useState(null);
    const [turmasInfantil, setTurmasInfantil] = useState([
        { serie: "Infantil I", turno: "Manhã" } 
    ]);

    const [areas, setAreas] = useState([
        {
            nome: "O eu, o outro e nós",
            descricao: "Respeitar e expressar sentimentos e emoções.",
            competencias: [
                "Respeitar e expressar sentimentos e emoções.",
                "Atuar em grupo demonstrando interesse em construir novas relações.",
                "Conhecer e respeitar regras de convivência social.",
            ],
            avaliacao: tiposAvaliacao.map((tipo) => ({ tipo, nota: null })),
        },
    ]);
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");

    const UserId = useUserId();
    const [User, setUser] = useState([]);

    useEffect(() => {
        const fetchUser = async () => {
            try {
                const userData = await getUserById(UserId); // Aguarda a resposta
                setUser(userData); // Armazena no estado
            } catch (error) {
                console.error("Erro ao buscar usuário:", error);
            }
        };

        if (UserId) {
            fetchUser(); // Chama a função assíncrona
        }
    }, [UserId]);

    const handleTipoAvaliacaoChange = (value) => {
        setTipoAvaliacaoMax(value);

        // Gerar automaticamente os tipos de avaliação até o limite selecionado
        const avaliacaoNumero = parseInt(value.replace("AV", ""), 10);
        const novasAvaliacoes = Array.from(
            { length: avaliacaoNumero },
            (_, i) => `AV${i + 1}`
        );
        setTiposAvaliacao(novasAvaliacoes);

        // Atualizar os tipos de avaliação em todas as áreas
        const updatedAreas = areas.map((area) => ({
            ...area,
            avaliacao: novasAvaliacoes.map((tipo) => ({ tipo, nota: null })),
        }));
        setAreas(updatedAreas);
    };

    const handleAddResponsavel = () => {
        setResponsaveis([...responsaveis, { nome: "", cargo: "" }]);
    };

    const handleRemoveResponsavel = (index) => {
        const updated = responsaveis.filter((_, idx) => idx !== index);
        setResponsaveis(updated);
    };

    const handleResponsavelChange = (index, field, value) => {
        const updated = [...responsaveis];
        updated[index][field] = value;
        setResponsaveis(updated);
    };

    const handleAddArea = () => {
        setAreas([
            ...areas,
            {
                nome: "",
                descricao: "",
                competencias: [],
                avaliacao: tiposAvaliacao.map((tipo) => ({ tipo, nota: null })),
            },
        ]);
    };

    const handleRemoveArea = (index) => {
        const updatedAreas = areas.filter((_, idx) => idx !== index);
        setAreas(updatedAreas);
    };

    const handleAreaChange = (index, field, value) => {
        const updatedAreas = [...areas];
        updatedAreas[index][field] = value;
        setAreas(updatedAreas);
    };

    const handleCompetenciaChange = (areaIndex, competenciaIndex, value) => {
        const updatedAreas = [...areas];
        updatedAreas[areaIndex].competencias[competenciaIndex] = value;
        setAreas(updatedAreas);
    };

    const handleAddCompetencia = (areaIndex) => {
        const updatedAreas = [...areas];
        updatedAreas[areaIndex].competencias.push("");
        setAreas(updatedAreas);
    };

    // Atualizar para garantir que o valor correto seja enviado ao backend



    const handleCreateBoletim = async () => {
        setMessage("");
        setError("");

        try {
            // Validação de campos obrigatórios
            if (!descricao.trim() || !nivelEnsino) {
                setError("Preencha todos os campos obrigatórios.");
                return;
            }

            if (responsaveis.some((resp) => !resp.nome.trim() || !resp.cargo.trim())) {
                setError("Preencha todos os campos obrigatórios em Responsáveis.");
                return;
            }

            // Normalizar periodType para valores aceitos pelo backend
            const normalizedPeriodType = periodType === "Bimestral" ? "Bimestre" :
                periodType === "Semestral" ? "Semestre" : periodType;

            const payload = {
                tenantId,
                anoLetivo,
                descricao,
                nivelEnsino,
                status,
                periodType: normalizedPeriodType,
                criadoPor:User?.firstName+" "+User?.lastName,
                responsaveis,
                observacoesGerais,
                dataEncerramento,
                tiposAvaliacao,
                areas,
                mediaGeral,
                turmasInfantil,
            };

            console.log(payload);

            await apiAxios.post("/api/boletims/boletins", payload);
            setMessage("Boletim criado com sucesso!");

            // Resetar campos do formulário
            setAnoLetivo(new Date().getFullYear().toString());
            setDescricao("");
            setNivelEnsino("");
            setStatus("Ativo");
            setPeriodType("Bimestral"); // Reset padrão para Bimestral
            setResponsaveis([{ nome: "", cargo: "" }]);
            setObservacoesGerais("");
            setDataEncerramento("");
            setTipoAvaliacaoMax("AV3");
            setTiposAvaliacao(["AV1", "AV2", "AV3"]);
            setMediaGeral(null);
            setAreas([
                {
                    nome: "O eu, o outro e nós",
                    descricao: "Respeitar e expressar sentimentos e emoções.",
                    competencias: [
                        "Respeitar e expressar sentimentos e emoções.",
                        "Atuar em grupo demonstrando interesse em construir novas relações.",
                        "Conhecer e respeitar regras de convivência social.",
                    ],
                    avaliacao: tiposAvaliacao.map((tipo) => ({ tipo, nota: null })),
                },
            ]);
        } catch (error) {
            console.error("Erro ao criar o boletim:", error);
            setError(
                error.response?.data?.message || "Erro ao criar o boletim. Tente novamente."
            );
        }
    };

    const handleAddTurmaInfantil = () => {
        setTurmasInfantil([...turmasInfantil, { serie: "", turno: "" }]);
    };

    const handleRemoveTurmaInfantil = (index) => {
        const updated = turmasInfantil.filter((_, idx) => idx !== index);
        setTurmasInfantil(updated);
    };

    const handleTurmaChange = (index, field, value) => {
        const updated = [...turmasInfantil];
        updated[index][field] = value;
        setTurmasInfantil(updated);
    };

    return (
        <div className={styles.container}>
            <div className={styles.form}>
                {/* Campos do Formulário */}
                <label>Ano Letivo</label>
                <select value={anoLetivo} onChange={(e) => setAnoLetivo(e.target.value)}>
                    <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                    <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                </select>

                <label>Descrição</label>
                <textarea
                    value={descricao}
                    onChange={(e) => setDescricao(e.target.value)}
                    placeholder="Digite a descrição"
                />

                <label>Nível de Ensino</label>
                <select value={nivelEnsino} onChange={(e) => setNivelEnsino(e.target.value)}>
                    <option value="">Selecione o nível</option>
                    <option value="Educação Infantil">Educação Infantil</option>
                    <option value="Fundamental I">Fundamental I</option>
                    <option value="Fundamental II">Fundamental II</option>
                    <option value="Ensino Médio">Ensino Médio</option>
                </select>

                {nivelEnsino === "Educação Infantil" && (
                    <>
                        <label>Turmas (Série e Turno)</label>
                        {turmasInfantil.map((turma, index) => (
                            <div key={index} className={styles.turmaSection}>
                                <select value={turma.serie} onChange={(e) => handleTurmaChange(index, "serie", e.target.value)}>
                                    <option value="">Selecione a Série</option>
                                    <option value="Infantil I">Infantil I</option>
                                    <option value="Infantil II">Infantil II</option>
                                    <option value="Infantil III">Infantil III</option>
                                    <option value="Infantil IV">Infantil IV</option>
                                    <option value="Infantil V">Infantil V</option>
                                </select>

                                <select value={turma.turno} onChange={(e) => handleTurmaChange(index, "turno", e.target.value)}>
                                    <option value="">Selecione o Turno</option>
                                    <option value="Manhã">Manhã</option>
                                    <option value="Tarde">Tarde</option>
                                </select>

                                <button type="button" onClick={() => handleRemoveTurmaInfantil(index)} className={styles.removeButton}>
                                    Remover
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddTurmaInfantil} className={styles.addButton}>
                            Adicionar Turma
                        </button>
                    </>
                )}

                <label>Tipo de Período</label>
                <select value={periodType} onChange={(e) => setPeriodType(e.target.value)}>
                    <option value="Bimestral">Bimestral</option>
                    <option value="Semestral">Semestral</option>
                </select>

                <label>Responsáveis</label>
                {responsaveis.map((responsavel, index) => (
                    <div key={index} className={styles.responsavelSection}>
                        <input
                            type="text"
                            placeholder="Nome do Responsável"
                            value={responsavel.nome}
                            onChange={(e) => handleResponsavelChange(index, "nome", e.target.value)}
                        />
                        <input
                            type="text"
                            placeholder="Cargo"
                            value={responsavel.cargo}
                            onChange={(e) => handleResponsavelChange(index, "cargo", e.target.value)}
                        />
                        <button
                            type="button"
                            onClick={() => handleRemoveResponsavel(index)}
                            className={styles.removeButton}
                        >
                            Remover
                        </button>
                    </div>
                ))}
                <button
                    type="button"
                    onClick={handleAddResponsavel}
                    className={styles.addButton}
                >
                    Adicionar Responsável
                </button>

                {/* Condicional para Áreas ou Tipos de Avaliação */}
                {nivelEnsino === "Educação Infantil" ? (
                    <>
                        <label>Áreas</label>
                        {areas.map((area, index) => (
                            <div key={index} className={styles.areaSection}>
                                <input
                                    type="text"
                                    placeholder="Nome da área"
                                    value={area.nome}
                                    onChange={(e) =>
                                        handleAreaChange(index, "nome", e.target.value)
                                    }
                                />
                                <textarea
                                    placeholder="Descrição"
                                    value={area.descricao}
                                    onChange={(e) =>
                                        handleAreaChange(index, "descricao", e.target.value)
                                    }
                                />
                                <label>Competências</label>
                                {area.competencias.map((competencia, compIndex) => (
                                    <input
                                        key={compIndex}
                                        type="text"
                                        placeholder="Competência"
                                        value={competencia}
                                        onChange={(e) =>
                                            handleCompetenciaChange(index, compIndex, e.target.value)
                                        }
                                    />
                                ))}
                                <button
                                    type="button"
                                    onClick={() => handleAddCompetencia(index)}
                                    className={styles.addButton}
                                >
                                    Adicionar Competência
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleRemoveArea(index)}
                                    className={styles.removeButton}
                                >
                                    Remover Área
                                </button>
                            </div>
                        ))}
                        <button type="button" onClick={handleAddArea} className={styles.addButton}>
                            Adicionar Área
                        </button>
                    </>
                ) : (
                    <>
                        <label>Tipos de Avaliação</label>
                        <select
                            value={tipoAvaliacaoMax}
                            onChange={(e) => handleTipoAvaliacaoChange(e.target.value)}
                        >
                            <option value="AV1">AV1</option>
                            <option value="AV2">AV2</option>
                            <option value="AV3">AV3</option>
                            <option value="AV4">AV4</option>
                            <option value="AV5">AV5</option>
                        </select>

                        <ul>
                            {tiposAvaliacao.map((avaliacao, index) => (
                                <li key={index}>{avaliacao}</li>
                            ))}
                        </ul>

                        <label>Média Geral</label>
                        <input
                            type="number"
                            value={mediaGeral || ""}
                            onChange={(e) => setMediaGeral(Number(e.target.value))}
                            placeholder="Digite a média geral"
                            maxLength={10}
                        />
                    </>
                )}
                <button
                    type="button"
                    onClick={handleCreateBoletim}
                    className={styles.submitButton}
                >
                    Criar Boletim
                </button>
            </div>

            {error && <p className={`${styles.message} ${styles.error}`}>{error}</p>}
            {message && <p className={`${styles.message} ${styles.success}`}>{message}</p>}
        </div>
    );
};

export default BoletimCreateComponent;
