import React, { useEffect, useState } from 'react';
import styles from './BoletimComponent.module.css';
import apiAxios from '../../../../api/apiAxios';

const BoletimComponent = ({ boletimAluno, tenantId, boletimId, alunoId }) => {
    const [bimestres, setBimestres] = useState(boletimAluno.bimestres || []);
    const [alunoBoletimId, setAlunoBoletimId] = useState(boletimAluno._id || null);
    const [notasEditadas, setNotasEditadas] = useState({});
    const [frequencia, setFrequencia] = useState([]);
    const [ano, setAno] = useState([]);
    const [periodType, setPeriodType] = useState([]);
    const [serie, setSerie] = useState([]);
    const [turno, setTurno] = useState([]);
    const [dadosCarregados, setDadosCarregados] = useState(false);

    const identificarPeriodo = (arrayPeriodos) => {
        if (!Array.isArray(arrayPeriodos) || arrayPeriodos.length === 0) {
            return null; // Retorna `null` se o array estiver vazio ou inválido
        }

        // Verifica a quantidade de períodos e retorna o tipo correspondente
        switch (arrayPeriodos.length) {
            case 4:
                if (arrayPeriodos.some(p => p.bimestre)) return "bimestre";
                if (arrayPeriodos.some(p => p.trimestre)) return "trimestre";
                if (arrayPeriodos.some(p => p.quadrimestre)) return "quadrimestre";
                return "bimestre"; // Padrão para 4 períodos
            case 3:
                return "trimestre";
            case 2:
                return "semestre";
            case 1:
                return "anual";
            default:
                return null; // Caso não identifique nenhum padrão
        }
    };


    // ✅ Buscar boletim atualizado
    useEffect(() => {
        const fetchBoletim = async () => {
            try {
                const response = await apiAxios.get(`/api/boletims/boletins/aluno`, {
                    params: { tenantId, boletimId, alunoId },
                });

                if (response.data && response.data.data.length > 0) {
                    const boletim = response.data.data[0];
                    setBimestres(boletim.bimestres);
                    setAlunoBoletimId(boletim._id);
                    setAno(new Date(boletim.createdAt).getUTCFullYear().toString()); // Extrai apenas o ano
                    setPeriodType(identificarPeriodo(boletim.bimestres)); // Ajuste conforme os dados
                    setSerie(boletim.serie);
                    setTurno(boletim.turno);
                    setDadosCarregados(true); // Indica que os dados do boletim foram carregados
                }
            } catch (error) {
                console.error('❌ Erro ao buscar boletim:', error);
            }
        };

        fetchBoletim();
    }, [boletimId, alunoId]); // ✅ Só chama uma vez quando o boletim muda

    console.log(periodType)
    console.log(ano)

    // ✅ Buscar frequência APENAS quando os dados do boletim estiverem carregados
    useEffect(() => {
        if (dadosCarregados && periodType && ano && serie && turno && alunoId) {
            const fetchFrequencia = async () => {
                try {
                    const response = await apiAxios.get(`/api/attendance/${tenantId}/periodo`, {
                        params: { // 🔴 Agora os dados vão como `query params`
                            periodType,
                            serie,
                            turno,
                            ano,
                            alunoId
                        }
                    });
                    setFrequencia(response.data);
                } catch (error) {
                    console.error("❌ Erro ao buscar frequência:", error);
                }
            };

            fetchFrequencia();
        }
    }, [dadosCarregados, periodType, ano, serie, turno, alunoId]);



    console.log("frequencia:", frequencia);


    // ✅ Atualizar nota localmente e salvar no backend
    const handleNotaChange = (bimestreIndex, materiaNome, avaliacaoIndex, newNota) => {
        if (newNota > 10) {
            alert("A nota não pode ser maior que 10");
            return;
        }

        // Atualiza localmente as notas editadas sem enviar imediatamente para a API
        setNotasEditadas(prev => ({
            ...prev,
            [`${bimestreIndex}-${materiaNome}-${avaliacaoIndex}`]: newNota
        }));

        // Atualiza o estado local dos bimestres para refletir a mudança imediatamente
        setBimestres(prevBimestres => {
            const updatedBimestres = JSON.parse(JSON.stringify(prevBimestres));
            const materia = updatedBimestres[bimestreIndex].notas.find(nota => nota.materia === materiaNome);
            if (materia && materia.avaliacoes[avaliacaoIndex]) {
                materia.avaliacoes[avaliacaoIndex].nota = newNota === "" ? "" : parseFloat(newNota);
            }
            return updatedBimestres;
        });
    };

    // ✅ Salvar a nota editada no backend após um pequeno atraso
    const saveNota = async (bimestreIndex, materiaNome, avaliacaoIndex) => {
        if (!notasEditadas[`${bimestreIndex}-${materiaNome}-${avaliacaoIndex}`]) return;

        const updatedBimestres = JSON.parse(JSON.stringify(bimestres));
        const materia = updatedBimestres[bimestreIndex].notas.find(nota => nota.materia === materiaNome);

        if (!materia) {
            console.error("❌ Matéria não encontrada!");
            return;
        }

        try {
            const payload = { bimestres: updatedBimestres };

            console.log("📤 Enviando payload para API:", JSON.stringify(payload, null, 2));

            if (alunoBoletimId) {
                await apiAxios.put(`/api/boletims/boletins/aluno/${alunoBoletimId}`, payload);
                console.log("✅ Notas salvas com sucesso!");

                // Remove do estado de notas editadas após salvar
                setNotasEditadas(prev => {
                    const newEditadas = { ...prev };
                    delete newEditadas[`${bimestreIndex}-${materiaNome}-${avaliacaoIndex}`];
                    return newEditadas;
                });

            } else {
                console.error("❌ Erro: ID do boletim do aluno não encontrado.");
            }
        } catch (error) {
            console.error("❌ Erro ao salvar notas:", error);
        }
    };

    // 🚀 Obtendo todas as matérias únicas
    const todasMaterias = bimestres.length > 0
        ? [...new Set(bimestres.flatMap((bimestre) => bimestre.notas.map((nota) => nota.materia)))]
        : [];

    // 🚀 Obtendo a **quantidade máxima de avaliações** para estruturar a tabela corretamente
    const maxAvaliacoes = Math.max(
        ...bimestres.flatMap((bimestre) =>
            bimestre.notas.flatMap((nota) => nota.avaliacoes.length)
        )
    );

    // Função para calcular a média das avaliações de uma matéria em um período específico
    const calcularMediaMateria = (avaliacoes) => {
        const notasValidas = avaliacoes.filter(avaliacao => avaliacao.nota !== null);
        if (notasValidas.length === 0) return null;
        const soma = notasValidas.reduce((acc, avaliacao) => acc + avaliacao.nota, 0);
        return (soma / notasValidas.length).toFixed(2);
    };

    // Criar um objeto que armazenará as médias das matérias por período
    const mediasPorMateriaPeriodo = todasMaterias.map((materia) => {
        return {
            materia,
            medias: bimestres.map((bimestre) => {
                const materiaData = bimestre.notas.find(nota => nota.materia === materia);
                return {
                    bimestre: bimestre.bimestre,
                    media: materiaData ? calcularMediaMateria(materiaData.avaliacoes) : "N/A",
                };
            }),
        };
    });

    // Calcular a média final de cada matéria considerando todos os períodos
    const mediaFinalPorMateria = mediasPorMateriaPeriodo.map((materiaData) => {
        const mediasValidas = materiaData.medias
            .map(m => parseFloat(m.media))
            .filter(nota => !isNaN(nota));

        return {
            materia: materiaData.materia,
            mediaFinal: mediasValidas.length ? (mediasValidas.reduce((acc, nota) => acc + nota, 0) / mediasValidas.length).toFixed(2) : "N/A",
        };
    });

    return (
        <div className={styles.boletim}>
            <div className={styles.boletimTitulo}>Notas</div>
            {bimestres.length === 0 ? (
                <p className={styles.mensagemErro}>Nenhuma nota disponível para este boletim.</p>
            ) : (
                <>
                    <table className={styles.boletimTabela}>
                        <thead>
                            <tr>
                                <th>Disciplina</th>
                                {bimestres.map((bimestre, idx) => (
                                    <th key={idx} colSpan={maxAvaliacoes}>{bimestre.bimestre}</th>
                                ))}
                            </tr>
                            <tr>
                                <th></th>
                                {bimestres.map((bimestre, idx) =>
                                    Array.from({ length: maxAvaliacoes }).map((_, i) => (
                                        <th key={`av-${idx}-${i}`}>{`AV${i + 1}`}</th>
                                    ))
                                )}
                            </tr>
                        </thead>
                        <tbody>
                            {todasMaterias.map((materia, materiaIndex) => (
                                <tr key={materiaIndex}>
                                    <td>{materia}</td>
                                    {bimestres.map((bimestre, bimestreIndex) => {
                                        const materiaData = bimestre.notas.find(nota => nota.materia === materia) || { avaliacoes: [] };

                                        return (
                                            <>
                                                {Array.from({ length: maxAvaliacoes }).map((_, avaliacaoIndex) => (
                                                    <td key={`av-${bimestreIndex}-${avaliacaoIndex}`}>
                                                        <input
                                                            type="number"
                                                            value={
                                                                notasEditadas[`${bimestreIndex}-${materia}-${avaliacaoIndex}`] ??
                                                                materiaData.avaliacoes[avaliacaoIndex]?.nota ?? ""
                                                            }
                                                            onChange={(e) =>
                                                                handleNotaChange(bimestreIndex, materia, avaliacaoIndex, e.target.value)
                                                            }
                                                            onBlur={() =>
                                                                saveNota(bimestreIndex, materia, avaliacaoIndex)
                                                            }
                                                        />
                                                    </td>
                                                ))}
                                            </>
                                        );
                                    })}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    {/* 🚀 Tabela de Médias por Período */}
                    {/* 🚀 Tabela de Médias por Matéria e Período */}
                    <div className={styles.boletimTitulo}>Médias por Matéria e Período</div>
                    <table className={styles.boletimTabela}>
                        <thead>
                            <tr>
                                <th>Disciplina</th>
                                {/* Criando os cabeçalhos dos bimestres, com colSpan para agrupar as colunas Nota/Faltas */}
                                {bimestres.map((bimestre, idx) => (
                                    <th key={`bim-${idx}`} colSpan={2}>{bimestre.bimestre}</th>
                                ))}
                                <th>Média Final</th>
                            </tr>
                            <tr>
                                <th></th>
                                {/* Criando os subcabeçalhos para Nota e Faltas abaixo de cada bimestre */}
                                {bimestres.map((_, idx) => (
                                    <>
                                        <th key={`nota-${idx}`}>Nota</th>
                                        <th key={`faltas-${idx}`}>Faltas</th>
                                    </>
                                ))}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {mediasPorMateriaPeriodo.map((materiaData, materiaIdx) => (
                                <tr key={materiaIdx}>
                                    {/* Nome da disciplina */}
                                    <td>{materiaData.materia}</td>

                                    {/* Para cada bimestre, exibir nota e faltas lado a lado */}
                                    {bimestres.map((bimestre, bimestreIdx) => {
                                        const mediaData = materiaData.medias.find(m => m.bimestre === bimestre.bimestre);
                                        const frequenciaData = frequencia.find(f => f.periodo === bimestre.bimestre);

                                        return (
                                            <React.Fragment key={`bim-${materiaIdx}-${bimestreIdx}`}>
                                                <td>{mediaData?.media ?? "0"}</td>
                                                <td>
                                                    {frequenciaData
                                                        ? frequenciaData.diasFaltas.length
                                                        : "0"}
                                                </td>
                                            </React.Fragment>
                                        );
                                    })}

                                    {/* Média final da matéria */}
                                    <td><strong>{mediaFinalPorMateria.find(m => m.materia === materiaData.materia)?.mediaFinal ?? "0"}</strong></td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                </>
            )}
        </div>
    );
};

export default BoletimComponent;
