import React from 'react'
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';
import BoletimAlunoDetailComponent from '../../../../components/pagesComponents/BoletimComponents/BoletimAlunoDetail/BoletimAlunoDetailComponent';

const BoletimCoordenacaoDetailPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', ['agenda','Informes','Atividades','Chat','Cardápio']);
    return (
        <BoletimAlunoDetailComponent itemsPages={itemsPagesList} />
    );
}

export default BoletimCoordenacaoDetailPage