import { IoMdTime } from "react-icons/io";
import { IoChatboxEllipsesOutline } from "react-icons/io5";
import { LuApple } from "react-icons/lu";
import { MdCalendarToday, MdOutlineClass, MdOutlineFormatAlignJustify, MdOutlineSpaceDashboard } from "react-icons/md";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import { RiMegaphoneLine } from "react-icons/ri";
import { TfiAgenda } from "react-icons/tfi";
import { GrVmMaintenance } from "react-icons/gr";
import { FaListCheck } from "react-icons/fa6";
import { PiStudent } from "react-icons/pi";
import { IoNotificationsOutline } from "react-icons/io5";

export const itemsPages = (selectedName) => [
    { id: 1, name: "Painel", icon: <MdOutlineSpaceDashboard />, link: "/" },
    { id: 2, name: "Agenda", icon: <TfiAgenda />, link: "/agenda" },
    { id: 3, name: "Planos", icon: <MdOutlineClass />, link: "/planos" },
    { id: 4, name: "Calendário", icon: <MdCalendarToday />, link: "/calendario" },
    { id: 5, name: "Cardápio", icon: <LuApple />, link: "/cardapio" },
    { id: 6, name: "Informes", icon: <RiMegaphoneLine />, link: "/informes" },
    { id: 7, name: "Atividades", icon: <PiPencilSimpleLineBold />, link: "/atividades" },
    { id: 8, name: "Chat", icon: <IoChatboxEllipsesOutline />, link: "/chat" },
    { id: 9, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, link: "/fichas" },
    { id: 10, name: "Horários", icon: <IoMdTime />, link: "/horarios" },
];

export const itemsPagesWithExclusions = (selectedName, excludeNames = []) => {
    const allItems = [
        { id: 1, name: "Painel", icon: <MdOutlineSpaceDashboard />, link: "/" },
        { id: 2, name: "Agenda", icon: <TfiAgenda />, link: "/agenda" },
        { id: 3, name: "Planos", icon: <MdOutlineClass />, link: "/planos" },
        { id: 4, name: "Calendário", icon: <MdCalendarToday />, link: "/calendario" },
        { id: 5, name: "Cardápio", icon: <LuApple />, link: "/cardapio" },
        { id: 6, name: "Informes", icon: <RiMegaphoneLine />, link: "/informes" },
        { id: 7, name: "Atividades", icon: <PiPencilSimpleLineBold />, link: "/atividades" },
        { id: 8, name: "Chat", icon: <IoChatboxEllipsesOutline />, link: "/chat" },
        { id: 9, name: "Fichas", icon: <MdOutlineFormatAlignJustify />, link: "/fichas" },
        { id: 10, name: "Horários", icon: <IoMdTime />, link: "/horarios" },
        { id: 11, name: "Frequência", icon: <FaListCheck />, link: "/frequencia" },
        { id: 12, name: "Boletim", icon: <PiStudent />, link: "/boletim" },
        { id: 13, name: "Notificações", icon: <IoNotificationsOutline />, link: "/notificacoes" },
    ];

    return allItems
        .filter(item => !excludeNames.includes(item.name))  // Excluir itens com base na lista de exclusões
        .map(item => ({
            ...item,
            isSelect: item.name === selectedName,
        }));
};


export const itemsPagesWithExclusionsAdminMaster = (selectedName, excludeNames = []) => {
    const allItems = [
        { id: 1, name: "Painel", icon: <MdOutlineSpaceDashboard />, link: "/" },
        { id: 2, name: "Tenants", icon: <GrVmMaintenance />, link: "/manager-tenants" },
    ];

    return allItems
        .filter(item => !excludeNames.includes(item.name))  // Excluir itens com base na lista de exclusões
        .map(item => ({
            ...item,
            isSelect: item.name === selectedName,
        }));
};