import React from 'react';
import BoletinsAlunosComponent from '../../../../components/pagesComponents/BoletimComponents/BoletinsAlunos/BoletinsAlunosComponent';
import { itemsPagesWithExclusions } from '../../../../components/itemsPage/itemsPage';

const BoletinsCoordenacaoAlunosPage = () => {
    const itemsPagesList = itemsPagesWithExclusions('Boletim', ['agenda','Informes','Atividades','Chat','Cardápio']);

    return (
        <BoletinsAlunosComponent itemsPages={itemsPagesList} />
    );
};

export default BoletinsCoordenacaoAlunosPage;
