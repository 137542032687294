import React from 'react'
import NotificacoesComponent from '../../../components/pagesComponents/NotificacoesComponents/NotificacoesComponent'
import { itemsPagesWithExclusions } from '../../../components/itemsPage/itemsPage';

const NotificacoesSecretariaPage = () => {
  const itemsPagesList = itemsPagesWithExclusions('Notificações', ['Atividades', 'Chat']);
  return (
    <NotificacoesComponent itemsPages={itemsPagesList}/>
  )
}

export default NotificacoesSecretariaPage